<template>
<div :key="iddiv">
  <div>
    <div class="my-3 my-md-5">
      <div class="container" id="dashboard" style="position: relative;">
        <b-alert
          style="position: absolute;width: 100%;"
          :show="dismissCountDown"
          fade
          dismissible
          variant="warning"
          @dismissed="dismissCountDown=0"
        >
          {{alertMessage}}
        </b-alert>
        <div class="page-header">
          <h1 class="page-title">
            Dashboard - Metas
          </h1>
        </div>

        <div class="row">
          <form name="day">
            <div class="col-12 form-group filter-day">
              <div class="selectgroup selectgroup-pills">
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="all" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('all');">Todos os Dias</span>
              </label>
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="thirty" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('thirty');">30 Dias</span>
              </label>
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="fifteen" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('fifteen');">15 Dias</span>
              </label>
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="seven" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('seven');">7 Dias</span>
              </label>
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="yesterday" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('yesterday');">Ontem</span>
              </label>
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="today" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('today');">Hoje</span>
              </label>
              <label class="selectgroup-itx'em">
                <HotelDatePicker class="item-date-picker" format="DD-MM-YYYY" @confirm="customDateConfirmed()" v-model="customDate" type="date" :clearable="false" confirm range placeholder="Selecionar data customizada"></HotelDatePicker>
              </label>
              </div>

            </div>
          </form>
        </div>
        <hr class="mt-0 pt-0">

        <template>
          <div class="row">
            <sales-table-card :label="['DISCRIÇÃO DA META', 'OBJETIVO META', 'RECEITAS PREVISTAS', 'QUANTITATIVOS PREVISTOS', 'RECEITAS REALIZADAS', 'QUANTITIVOS REALIZADOS', '% ALCANÇADO']" :title="'METAS GERAIS'" :dataGoals="dashboard.general" :status="'goal'" :size="'large'"></sales-table-card>
            <sales-table-card :label="['COLABORADOR', 'DESCRIÇÃO DA META', 'OBJETIVO META', 'RECEITAS PREVISTAS', 'QUANTITATIVOS PREVISTOS', 'RECEITAS REALIZADAS', 'QUANTITATIVOS REALIZADOS', '% ALCANÇADO']" :title="'METAS INDIVIDUAL'" :dataGoals="dashboard.individual" :status="'goal'" :size="'large'"></sales-table-card>
          </div>

          <!-- <half-circle-spinner v-if="!isLoaded" :size="60" color="#ddd"></half-circle-spinner> -->

        </template>
      </div>
    </div>

  </div>
</div>
</template>

<script>
// muitas funções não são utilizadas ainda (está com dados mockados!!!)
import Vue from "vue";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import cardInfo from "@/views/dashboard/new-card-info";
import HotelDatePicker from 'vue2-datepicker';
import SalesTableCard from '../sales-table-card.vue';
import 'vue2-datepicker/index.css';
import { HollowDotsSpinner } from 'epic-spinners'
import { HalfCircleSpinner } from 'epic-spinners'
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { func } from "@/functions";
import { userService } from '../../../components/common/services/user';
import { eventService } from "../../../components/common/services/event";
import { dashboardService } from "../../../components/common/services/dashboard";
Vue.component('v-select', vSelect)
const moment = extendMoment(Moment);

export default {
  name: 'DashboardCorporativo',
  mixins: [func],
  data() {
    return {
      key: 0,
      isLoaded: false,
      datepickerHidden: true,
      overtimeData: [],
      customDate: '',
      datepicker: {
        id: 1,
        format: 'DD/MM/YYYY',
        minNights: 0,
        maxNights: 60,
        hoveringTooltip: true,
        displayClearButton: true,
        startDate: new Date('2003-01-01'),
        endDate: new Date('2055-01-01'),
        startingDateValue: new Date(),
        ptBr: {
          night: 'Dia',
          nights: 'Dias',
          'day-names': ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
          'check-in': 'Início',
          'check-out': 'Fim',
          'month-names': ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        },
      },
      iddiv: 1,
      processing: false,
      form: {
        searchtype: 'today',
        id_base: '',
        id_evento: '',
        date: '',
        hour: '',
        selectedDate: {
          start: moment(new Date()).format("DD/MM/YYYY"),
          end: '',
        }
      },
      selects: {
        base: [],
        events: [],
        days: [],
        hours: []
      },
      alertMessage: '',
      dismissSecs: 3,
      dismissCountDown: 0,
      dashboard: {
        individual: [
         { label: 'CLAUDIO', op1: 'VENDAS POR MÊS', op2: 'FINANCEIRO', op3: 'R$30.000,00', op4: '0', op5: 'R$15.000,00', op6: '1528', op7: '50%' },
          { label: 'VIVIANE', op1: 'VENDAS POR MÊS', op2: 'FINANCEIRO', op3: 'R$30.000,00', op4: '0', op5: 'R$15.000,00', op6: '1528', op7: '50%' },
          { label: 'GIOVANNA', op1: 'SEGUIDORES POR MÊS', op2: 'DIVULGAÇÃO', op3: 'R$ 0', op4: '1200', op5: 'R$ 0', op6: '600', op7: '50%' },
          { label: 'GIOVANNA', op1: 'CURTIDAS POR MÊS', op2: 'DIVULGAÇÃO', op3: 'R$ 0', op4: '1200', op5: 'R$ 0', op6: '600', op7: '50%' },
          { label: 'GIOVANNA', op1: 'POSTS POR MÊS', op2: 'DIVULGAÇÃO', op3: 'R$ 0', op4: '100', op5: 'R$ 0', op6: '50', op7: '50%' },
          { label: 'EDUARDO', op1: 'EMPRESAS POR MÊS', op2: 'AQUISIÇÃO', op3: 'R$ 0', op4: '25', op5: 'R$ 0', op6: '13', op7: '50%' },
          { label: 'EDUARDO', op1: 'PRODUTOS POR MÊS', op2: 'AQUISIÇÃO', op3: 'R$ 0', op4: '25', op5: 'R$ 0', op6: '13', op7: '50%' },
          { label: 'ALINE', op1: 'CADASTROS POR MÊS', op2: 'AQUISIÇÃO', op3: 'R$ 0', op4: '25', op5: 'R$ 0', op6: '13', op7: '50%' },
          { label: 'CRISTIANA', op1: 'ATENDIMENTO AO CLIENTE', op2: 'RETENÇÃO', op3: 'R$ 0', op4: '10', op5: 'R$ 0', op6: '5', op7: '50%' },
          { label: 'MATHEUS', op1: 'ATENDIMENTO AO SUPORTE', op2: 'RETENÇÃO', op3: 'R$ 0', op4: '10', op5: 'R$ 0', op6: '5', op7: '50%' }
        ],
       general: [
          { label: 'VENDAS POR MÊS', op1: 'FINANCEIRO', op2: 'R$60.000,00', op3: '0', op4: 'R$15.000,00', op5: '1528', op6: '50%' },
          { label: 'SEGUIDORES POR MÊS', op1: 'DIVULGAÇÃO', op2: 'R$ 0', op3: '1200', op4: 'R$ 0', op5: '600', op6: '50%' },
          { label: 'CURTIDAS POR MÊS', op1: 'DIVULGAÇÃO', op2: 'R$ 0', op3: '1200', op4: 'R$ 0', op5: '600', op6: '50%' },
          { label: 'POSTS POR MÊS', op1: 'DIVULGAÇÃO', op2: 'R$ 0', op3: '100', op4: 'R$ 0', op5: '50', op6: '50%' },
          { label: 'EMPRESAS POR MẼS', op1: 'AQUISIÇÃO', op2: 'R$ 0', op3: '25', op4: 'R$ 0', op5: '13', op6: '50%' },
          { label: 'PRODUTOS POR MÊS', op1: 'AQUISIÇÃO', op2: 'R$ 0', op3: '25', op4: 'R$ 0', op5: '13', op6: '50%' },
          { label: 'CADASTROS POR MÊS', op1: 'AQUISIÇÃO', op2: 'R$ 0', op3: '25', op4: 'R$ 0', op5: '13', op6: '50%' },
          { label: 'ATENDIMENTO AO CLIENTE', op1: 'RETENÇÃO', op2: 'R$ 0', op3: '10', op4: 'R$ 0', op5: '5', op6: '50%' },
          { label: 'ATENDIMENTO AO SUPORTE', op1: 'RETENÇÃO', op2: 'R$ 0', op3: '10', op4: 'R$ 0', op5: '5', op6: '50%' }
        ]
      }
    }
  },
  components: {
    cardInfo,
    SalesTableCard,
    HotelDatePicker,
    HollowDotsSpinner,
    HalfCircleSpinner
  },
  computed: {},
  created() {
    this.hideWaitAboveAll();
  },
  async mounted () {
  },
  methods: {
    async customDateConfirmed() {
      if (this.customDate.length > 1 && this.customDate[0]) {
        this.form.searchtype = ''
        if (moment(this.customDate[0]).isValid()) {
          this.form.selectedDate.start = moment(this.customDate[0]).format("DD-MM-YYYY")
        }
        if (moment(this.customDate[1]).isValid()) {
          this.form.selectedDate.end = moment(this.customDate[1]).format("DD-MM-YYYY")
        }
        await this.getDashboard()
      }
    },
    async getDashboard () {
      this.overtimeData = []
      this.isLoaded = false;

      if (finded.length > 0) {
        const filter = {
          start: this.form.selectedDate.start,
          end: this.form.selectedDate.end
        }

        if (!filter.start && !filter.end) {
          this.form.searchtype = 'today'
        }

        const res = await dashboardService.getAccess(this.getLoggedId(), filter)
      }
      this.isLoaded = true;
    },
    showAlert (message) {
      this.alertMessage = message
      this.dismissCountDown = this.dismissSecs
    },
    async startchanged(date) {
      this.isLoaded = false
      this.form.selectedDate.start = moment(date).isValid() ? moment(date).format("DD/MM/YYYY") : '';
      await this.getDashboard()
      this.isLoaded = true
      if (date) {
        this.form.searchtype = ''
      }
    },
    async endchanged(date) {
      this.isLoaded = false
      this.form.selectedDate.end = moment(date).isValid() ? moment(date).add(1,'days').format("DD/MM/YYYY") : '';
      await this.getDashboard()
      this.isLoaded = true
      if (date) {
        this.form.searchtype = ''
      }
    },
    checkifpersonalized() {
      Vue.nextTick().then(response => {
        if (this.form.selectedDate.start != '' && this.form.selectedDate.end != '') {
          this.search('');
        }
        else {
          if (this.form.selectedDate.start == '' && this.form.selectedDate.end == '' && this.form.searchtype == '') {
            this.search('today');
          }
        }
      });
    },
    toggleDate() {
      this.$refs.dtpicker.hideDatepicker();
      this.$refs.dtpicker.toggleDatepicker();
    },
    clear() {
      this.dashboard.values.loaded = false;
      this.dashboard.boletos.loaded = false;
      this.dashboard.occupation.loaded = false;
      this.dashboard.bychannel.loaded = false;
      this.dashboard.timetable.loaded = false;
    },
    async search(type) {
      this.isLoaded = false
      if (!type && this.customDate) {
        await this.getDashboard() // mantém o filtro custom ao trocar de empresa
        return false
      }
      this.form.searchtype = type ? type : this.form.searchtype
      let startDate = ''
      this.customDate = ''
      let endDate = moment()
      this.form.selectedDate.start = ''
      this.form.selectedDate.end = ''
      switch (this.form.searchtype) {
        case 'fifteen':
            startDate = moment().subtract(15, "days")
          break
        case 'seven':
            startDate = moment().subtract(7, "days")
          break
        case 'yesterday':
            startDate = moment().subtract(1, "days")
            endDate = ''
          break
        case 'thirty':
            startDate = moment().subtract(30, "days")
          break
        case 'today':
            startDate = moment()
            endDate = ''
          break
        case 'all':
            startDate = moment(new Date('2021-01-01'))
            endDate = moment()
          break
        case '':
            startDate = ''
            endDate = ''
          break
      }

      if (startDate) {
        this.form.selectedDate.start =  startDate.format("DD-MM-YYYY")
      }
      if (endDate) {
        this.form.selectedDate.end = endDate.format("DD-MM-YYYY")
      }
      await this.getDashboard()
      this.isLoaded = true
    },
  }
}
</script>

<style lang="scss">
#dashboard {
  .item-date-picker {
    .mx-input { 
      height: 28px;
    }
  }
  .vs__dropdown-toggle {
    border: 1px solid #dfdfdf;
    border-radius: 1px;
  }
  .datepicker__dummy-wrapper {
    border-radius: 12px;
    height: 26px !important;

    .datepicker__input {
      font-size: 14px;
      color: rgb(154, 160, 172);
      font-size: 11px;
      color: #9aa0ac;
      line-height: 2.2;
      height: 26px;
    }

    .datepicker__clear-button {
      margin: 8px -2px 0 0;
      font-size: 14px;
      height: 22px !important;
    }

    .datepicker__input:first-child {
      background: transparent url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjE4IiB2aWV3Qm94PSIwIDAgOCAxOCI+CiAgICA8cGF0aCBmaWxsPSIjOTU5OUFBIiBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik0uMTE5LjcxOGw3LjE1OCA3LjQwNy0uMDMzLS41NTEtNi43MzcgOC44ODlhLjQyNS40MjUgMCAwIDAgLjA4LjU5My40Mi40MiAwIDAgMCAuNTktLjA4bDYuNzM3LTguODg5YS40MjUuNDI1IDAgMCAwLS4wMzMtLjU1MUwuNzIzLjEyOEEuNDIuNDIgMCAwIDAgLjEyOC4xMmEuNDI1LjQyNSAwIDAgMC0uMDA5LjU5OHoiLz4KPC9zdmc+Cg==) no-repeat 100%/5px !important;
    }
  }

  .datepicker__wrapper {
    background: transparent url('/assets/icons/edit.svg') no-repeat 14px/13px !important;
    height: 22px !important;
  }

  .datepicker__clear-button svg {
    width: 10px !important;
    top: -10px !important;
  }

  @media (max-width: 1270px) {

  .input-group-prepend, .input-group-append, .input-group-btn, .input-group-text {
    margin-bottom: 2px;
    margin-top: 2px;
    font-weight: bold;
    padding-left: 0;
    background: transparent;
    border: none;
  }

  .input-group {
    display: block;
  }

  select {
    border-radius: 4px;
    width: 50%;
    display: flex;
  }
  }

    .input-group-prepend, .input-group-append, .input-group-btn, .input-group-text { 
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-weight: bold;
    color: hsl(0, 0%, 13%);

    }
  select {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: hsl(0,0%,29%);
  }


}
</style>
